import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: '0 auto',
  paddingLeft: theme.pageMarginHorizontal,
  paddingRight: theme.pageMarginHorizontal,

  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.pageMarginHorizontalMobile,
    paddingRight: theme.pageMarginHorizontalMobile,
  },
}));
